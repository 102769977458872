@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%;
    background-color: black !important;
    color: grey !important;
    font-family: 'Space Mono', monospace;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
	    z-index: 1;

}

#root {
    width: 100%;
    height: 100%;
}

.logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.text-animated {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    font-family: 'Space Mono', monospace;
}
